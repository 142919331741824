import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";
import { StyledHeaderContact, LinkedInContainer } from '.';

export const HeaderContact = () => {
  return (
    <StyledHeaderContact>
      <Row className="w-100">
        <Col xs={12} sm={6}>
          <span>Call us on <b>0800 368 5220</b></span>
        </Col>
        <Col xs={12} sm={6}>
          <LinkedInContainer>
            <span>Follow us</span>
            <a href="https://www.linkedin.com/company/inteckltd/" target="_blank" rel="noreferrer"><FaLinkedin /></a>
          </LinkedInContainer>
        </Col>
      </Row>
    </StyledHeaderContact>
  );
}

export default HeaderContact;