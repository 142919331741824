import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';
import { Link } from 'gatsby';

export const StyledNavigation = styled.div`
    z-index: 1;
    position: relative;
    background: #91d4e6;
    padding: 0px 40px;

    @media (max-width: 992px) { 
      height: unset;
      padding: 20px;
    }
`;

export const List = styled.ul`
  list-style-type: none; 
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;
  }
`;

export const Item = styled.li`
  display: inline-block;
  margin: 0;
  cursor: pointer;
  padding-left: 50px;

  @media (max-width: 992px) {
    padding: 0px;
    padding-top: 10px;
  }
`;

export const Toggle = styled(Navbar.Toggle)`
  border: none;
  border-color: transparent !important;
`;

export const Route = styled(Link)`
  color: white;

  &:hover {
    color: #182A64;
    text-decoration: none;
  }
`;