import * as React from "react"
import { Row, Col, Navbar, Nav, Dropdown } from 'react-bootstrap';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { GraphQLChildFixedImage } from "../../interfaces/graphql-image";
import { StyledNavigation, List, Toggle, Item, Route } from '.';

interface IProps {
  logo: GraphQLChildFixedImage;
}

const Navigation: React.FC<IProps> = ({ logo }) => {
  return (
    <StyledNavigation>
      <Navbar expand="lg" className="p-0">
        <Row className="w-100">
          <Col xs={10} sm={10} md={10} lg={2}>
            <Link to="/"><Img fixed={logo.childImageSharp.fixed} className="mt-2" /></Link>
          </Col>
          <Toggle aria-controls="basic-navbar-nav" className="align-self-center" />
          <Col xs={12} sm={12} md={12} lg={{span: 5, offset: 3}} xl={{span: 5, offset: 5}} className="d-flex align-items-center">
            <Navbar.Collapse id="basic-navbar-nav" className="h-100">
              <Nav className="w-100">
                <List>
                  <Item><Route to="/">Home</Route></Item>
                  <Item><Route to="/about">About</Route></Item>
                  <Item><Route to="/services">Services</Route></Item>
                  <Item><Route to="/vacancies">Vacancies</Route></Item>
                  <Item><Route to="/blogs">Blogs</Route></Item>
                  <Item><Route to="/contact">Contact</Route></Item>
                </List>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Navbar>
    </StyledNavigation>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "ids-logo.png" }) {
          childImageSharp {
            fixed(width: 150, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <Navigation logo={data.logo} />}
  />
);
