import styled from 'styled-components';

export const StyledHeaderContact = styled.div`
  width: 100%;
  height: 40px;
  background-color: #182A64;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  color: #FFFFFF;

  @media (max-width: 575.98px) { 
    height: 70px;
    text-align: center;
  }
`;

export const LinkedInContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 575.98px) { 
    justify-content: center;
    margin-top: 10px;
  }

  a {
    display: flex;
    color: #FFFFFF;

    svg {
      font-size: 26px;
      margin-left: 10px;
    }
  }
`;