import * as React from "react";
import { HeaderContact } from "../header-contact";
import { Navigation } from "../navigation";

export const Header = () => {
  return (
    <>
      <HeaderContact/>
      <Navigation/>
    </>
  );
}

export default Header;